
import {
  agencyMapActions,
  agencyMapGetters,
  agencyMapState
} from "@/store/modules/agency";
import {
  companiesMapActions,
  companiesMapGetters
} from "@/store/modules/companies";
import Vue from "vue";
import { userMapActions } from "@/store/modules/user";
import { get } from "lodash";
import { IUserModel } from "@/types";
interface IData {
  didInit: boolean;
  activeIndex: string;
  error: string;
  message: string;
  resourceNotFound: boolean;
  resourceNotFoundMsg: string;
  hideUserListView: boolean;
  errorMessage: string;
  successMessage: string;
  initialized: boolean;
  bulkAction: { disabled: boolean; title: string }[];
  emitOnAction: boolean;
  topButtonForUserList: string[];
}

export default Vue.extend({
  name: "SingleAgency",
  components: {
    UsersListView: () =>
      import("@/components/User/ListUsers/ListUsersTable.vue"),
    UserListPickerModal: () =>
      import("@/components/User/ListUsers/UserListPickerModal.vue")
  },
  data(): IData {
    return {
      didInit: false,
      activeIndex: "info",
      error: "",
      message: "",
      resourceNotFound: false,
      resourceNotFoundMsg:
        "Ooops! The agency you are looking for cannot be found.",
      hideUserListView: false,
      successMessage: "",
      errorMessage: "",
      initialized: false,
      bulkAction: [
        {
          title: "Add Users To Agency",
          disabled: false
        }
      ],
      emitOnAction: true,
      topButtonForUserList: ["Add New User To Agency"]
    };
  },
  computed: {
    ...agencyMapGetters(["getEditingAgency", "getAgencyById"]),
    ...agencyMapState(["editing", "makingApiRequest"]),
    ...companiesMapGetters(["companies"]),
    agencyId(): any {
      return this.$route.params.agencyId;
    },
    agency(): any {
      return this.getAgencyById(this.agencyId);
    },
    companyOptions(): { label: string; value: string }[] {
      const initData = this.$store.state.auth["initData"];
      const { companies = [] } = initData;
      return companies.map((company: any) => ({
        label: company.companyName,
        value: company._id
      }));
    }
  },
  methods: {
    ...userMapActions(["getUsers"]),
    ...agencyMapActions(["getAgency", "assignAgentsToAgencies", "getAgencies"]),
    ...companiesMapActions(["getCompanies"]),
    onToolbarItemClick(event: string) {
      switch (event) {
        case "save":
          this.submit();
          break;
        case "upload-banned-agents":
          if (this.$refs && this.$refs.usersListView)
            (this.$refs.usersListView as any).uploadBannedOrRestrictedUsers(
              "banned"
            );
          break;
        case "upload-restricted-agents":
          if (this.$refs && this.$refs.usersListView)
            (this.$refs.usersListView as any).uploadBannedOrRestrictedUsers(
              "restricted"
            );
          break;
        case "addUser":
          this.hideUserListView = true;
          this.$modal.show("selectUserModal");

          break;
      }
    },
    closedAddUserModal() {
      this.hideUserListView = false;
    },
    async promptAddUserToAgency(data: any) {
      let confirmInfo = "Are you sure you want to add: </br></br>";
      let agentIds: any[] = [];
      if (data.action && get(this.bulkAction, "0.title") === data.action) {
        const { selected } = data;
        agentIds = selected.map((d: IUserModel) => {
          return d._id;
        });
        const agencyIds = selected.map((d: IUserModel) => {
          return d.agencyId;
        });
        await this.getAgencies({ query: { _id__in: agencyIds } });
        selected.map((d: IUserModel) => {
          const agency = this.getAgencyById(d.agencyId);
          confirmInfo += `${d.firstName} ${d.lastName} ${
            d.agencyId && agency ? "from <b>" + agency.agencyName + "</b>" : ""
          } </br>`;
        });
      } else if (data._id) {
        agentIds = [data._id];
        await this.getAgency(data.agencyId);
        const agency = this.getAgencyById(data.agencyId);
        confirmInfo += `${data.firstName} ${data.lastName} ${
          data.agencyId && agency ? "from <b>" + agency.agencyName + "</b>" : ""
        }</br>`;
      }
      confirmInfo += `<br> to <b>${this.editing.agencyName}</b>?`;
      await this.$confirm(confirmInfo, "Info", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        dangerouslyUseHTMLString: true,
        type: "warning",
        closeOnClickModal: false,
        showClose: false,
        cancelButtonClass:
          "el-button rounded-none ml-0 mr-0 el-button--primary el-button--medium is-plain"
      });
      await this.addUserToAgency(agentIds);
    },
    async addUserToAgency(agentIds: any[]) {
      try {
        const agentsSize = agentIds.length;
        if (agentsSize && this.getEditingAgency) {
          await this.assignAgentsToAgencies({
            id: this.getEditingAgency._id,
            agentIds
          });
          const agentString = agentsSize > 1 ? "Agents" : "Agent";
          this.$appNotifySuccess(`${agentString} Added Successfully`);
          this.$modal.hide("selectUserModal");
        }
      } catch (error) {
        this.$appNotifyError(error.message);
        this.$bugSnagClient.notify(error);
      }
    },
    userListToButtonClicked(topButton: string) {
      switch (topButton) {
        case this.topButtonForUserList[0]:
          this.$router.push({
            path: "/admin/users/new",
            query: { agencyId: this.editing._id }
          });
          break;
      }
    },
    camelCaseToTitleCase(str: string): string {
      return str
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
        .replace(/^./, (str: string) => {
          return str.toUpperCase();
        });
    }
  },
  created() {
    this.getAgency(this.agencyId).then((values: any) => {
      if (values && values.status == "rejected") {
        this.resourceNotFound = true;
        this.errorMessage = "Error retrieving agency data";
      }
      this.initialized = true;
    });
  }
});
